@import "src/shared/styles/vars.colors";

.request-card, .request-service-card {
  gl-panel[theme='default'] {
    .drawer-box {
      padding: 0;
    }
  }
  gl-panel[theme='dark'] {
    .line-remains {
      background-color: #fff;
    }
  }
}
.commission {
  height: 40px;
  background-color: map-get($project-colors, "gradation", 700);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.requests-kanban {
  .cdk-virtual-scroll-content-wrapper {
    gl-request-card {
      margin-bottom: 0px;
    }
  }

}

.cdk-virtual-scroll-content-wrapper {
  gl-request-card {
    margin-bottom: 24px;
    display: block;
    margin-right: 12px;
  }
}
