@import "src/shared/styles/widjets/icon_tools";
@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/layout/typography";

@mixin btn-big {
  height: 48px;
  padding: 0 12px;
  &.btn_only-iconly {
    padding: 0 14px;
  }
  gap: 8px;
  &,
  span {
    @include text-big();
  }

  &.btn_link {
    padding: 0;
    min-height: 24px;
  }
}

@mixin btn-normal {
  height: 40px;
  padding: 0 12px;
  &.btn_only-iconly {
    padding: 0 10px;
  }
  gap: 6px;
  &,
  span {
    @include text-normal();
  }

  &.btn_link {
    padding: 0;
    min-height: 20px;
  }
}

@mixin btn-small {
  height: 32px;
  padding: 0 12px;
  &.btn_only-iconly {
    padding: 0 6px;
  }
  gap: 6px;
  &,
  span {
    @include text-normal();
  }

  &.btn_link {
    min-height: 18px;
    gap: 4px;
    padding: 0;

    .mat-icon {
      @include icon-size(18px)
    }
  }
}

@mixin btn-primary() {
  $default_color: map-get($project-colors, "text-icons", "light", "primary");
  background-color: map-get($project-colors, "main", 300);

  &,
  span {
    @include weight-medium();
    color: $default_color;
  }

  @include btn-iconly($default_color);

  &:not(:disabled) {
    &:hover {
      background-color: map-get($project-colors, "main", 400);
    }

    &:active, &.btn_loading {
      background-color: map-get($project-colors, "main", 200);
    }
  }
}

@mixin btn-floating() {
  $default_color: map-get($project-colors, "text-icons", "dark", "secondary");
  background-color: map-get($project-colors, "gradation", 800);

  &,
  span {
    @include weight-regular();
    color: $default_color;
  }

  @include btn-iconly($default_color);

  &:not(:disabled) {
    box-shadow: 0 8px 16px map-get($project-colors, "shadow");

    &:hover {
      background-color: map-get($project-colors, "gradation", 700);
    }

    &:active, &.btn_loading {
      background-color: map-get($project-colors, "gradation", 600);
    }
  }
}

@mixin btn-secondary() {
  $default_color: map-get($project-colors, "text-icons", "dark", "secondary");
  background-color: map-get($project-colors, "gradation", 700);

  &,
  span {
    @include weight-regular();
    color: $default_color;
  }
  @include btn-iconly($default_color);

  &:not(:disabled) {
    &:hover {
      background-color: map-get($project-colors, "gradation", 600);
    }

    &:active, &.btn_loading {
      background-color: map-get($project-colors, "gradation", 600);
      border-color: map-get($project-colors, "gradation", 500);
    }
  }
}

@mixin btn-outline() {
  $default_color: map-get($project-colors, "text-icons", "dark", "secondary");
  background-color: transparent;
  border-color: map-get($project-colors, "gradation", 600);

  &,
  span {
    @include weight-regular();
    color: $default_color;
  }
  @include btn-iconly($default_color);

  &:not(:disabled) {
    &:hover {
      background-color: map-get($project-colors, "gradation", 700);
      border-color: map-get($project-colors, "gradation", 500);
    }

    &:active, &.btn_loading {
      background-color: map-get($project-colors, "gradation", 700);
      border-color: map-get($project-colors, "gradation", 500);
    }
  }
}

@mixin btn-ghost($default_color, $hover_color, $active_color, $disabled_color) {
  background-color: transparent;

  &,
  span {
    @include weight-medium();
    color: $default_color
  }

  @include btn-iconly($default_color);

  &:not(:disabled) {
    &:hover {
      &,
      span {
        color: $hover_color;
      }

      @include btn-iconly($hover_color);
    }

    &:active, &.btn_loading {
      &,
      span {
        color: $active_color;
      }

      @include btn-iconly($active_color);
    }
  }
  &:disabled {
    background-color: transparent;

    &,
    span {
      color: $disabled_color;
    }

    @include btn-iconly($disabled_color);
  }
}

@mixin btn-link($default_color, $hover_color, $active_color, $disabled_color) {
  background-color: transparent;
  border-radius: 0;

  &,
  span {
    &:not(.c) {
      color: $default_color;
    }
  }

  &,
  span {
    @include weight-medium();
  }

  @include btn-iconly($default_color);

  &:not(:disabled) {
    &:hover {
      &,
      span {
        &:not(.c) {
          color: $hover_color;
        }
      }

      @include btn-iconly($hover_color);
    }

    &:active {
      &,
      span {
        &:not(.c) {
          color: $active_color;
        }
      }

      @include btn-iconly($active_color);
    }
  }
  &:disabled {
    &,
    span {
      &:not(.c) {
        color: $disabled_color;
      }
    }


    @include btn-iconly($disabled_color);
  }
}

// customs

@mixin btn-custom-white() {
  &.btn {
    &_primary {
    }

    &_floating {
    }

    &_secondary {
      background-color: map-get($project-colors, "gradation", 800);
    }

    &_outline {
    }

    &_ghost {
      $default_color: map-get($project-colors, "text-icons", "light", "primary");
      $hover_color: map-get($project-colors, "text-icons", "light", "secondary");
      $active_color: map-get($project-colors, "text-icons", "light", "primary");
      $disabled_color: map-get($project-colors, "text-icons", "light", "quaternary");
      @include btn-ghost($default_color, $hover_color, $active_color, $disabled_color);
    }

    &_link {

    }
  }
}

@mixin btn-custom-green {
  &.btn {
    &_primary {
    }

    &_floating {
    }

    &_secondary {
    }

    &_outline {
    }

    &_ghost {
    }

    &_link {
      @include btn-link(
          map-get($project-colors, "main", 200),
          map-get($project-colors, "main", 300),
          map-get($project-colors, "main", 300),
          map-get($project-colors, "text-icons", "dark", "quaternary"),
      );
    }
  }
}

@mixin btn-custom-black {
  &.btn {
    &_primary {
    }

    &_floating {
    }

    &_secondary {
    }

    &_outline {
    }

    &_ghost {
      $default_color: map-get($project-colors, "text-icons", "dark", "secondary");
      $hover_color: map-get($project-colors, "text-icons", "dark", "tertiary");
      $active_color: map-get($project-colors, "text-icons", "dark", "primary");
      $disabled_color: map-get($project-colors, "text-icons", "dark", "quaternary");
      @include btn-ghost($default_color, $hover_color, $active_color, $disabled_color)
    }

    &_link {
    }
  }
}

@mixin btn-custom-red {
  &.btn {
    &_primary {
    }

    &_floating {
    }

    &_secondary {
    }

    &_outline {
    }

    &_ghost {
    }

    &_link {
      @include btn-link(
          map-get($project-colors, "red", 200),
          map-get($project-colors, "red", 100),
          map-get($project-colors, "red", 100),
          map-get($project-colors, "text-icons", "dark", "quaternary"),
      );
    }
  }
}

// other

@mixin btn-disabled {
  $default_color: map-get($project-colors, "text-icons", "dark", "quaternary");
  cursor: not-allowed;
  background-color: map-get($project-colors, "gradation", 500);

  &,
  span {
    @include weight-regular;
    color: $default_color;
  }

  &.btn_primary {
    &, span {
      @include weight-medium();
    }
  }

  @include btn-iconly($default_color);
}

@mixin btn {
  border: 1px solid transparent;
  cursor: pointer;
  line-height: 100%;
  width: 100%;
  border-radius: 8px;

  &_left-iconly .btn_left-wrapper {
    display: block;
  }

  &_right-iconly .btn_right-wrapper {
    display: block;
  }

  &_only-iconly .btn_only-icon-wrapper {
    display: block;
  }

  &_icon-wrapper {
    display: none;
  }

  &,
  span {
    @include text;
    user-select: none;
  }
  & {
    transition: color $trs, background-color $trs, border-color $trs, box-shadow $trs;
  }

  //overflow: hidden;

  &.btn {
    &:disabled {
      @include btn-disabled;
    }
  }

  //&:not(:disabled):not(:active):focus {
  //  outline: 1px solid black;
  //}

  .mat-icon,
  &_icon-wrapper {
    @include icon-size(20px);
  }
}

@mixin btn-iconly($default_color) {
  .mat-icon {
    @include icon-color($default_color);
  }
}

.btn {
  @include btn;

  &.btn {
    // types

    &_primary {
      @include btn-primary;
    }

    &_floating {
      @include btn-floating;
    }

    &_secondary {
      @include btn-secondary;
    }

    &_outline {
      @include btn-outline;
    }

    &_ghost {
      $default_color: map-get($project-colors, "main", 200);
      $hover_color: map-get($project-colors, "main", 300);
      $active_color: map-get($project-colors, "main", 300);
      $disabled_color: map-get($project-colors, "text-icons", "dark", "quaternary");
      @include btn-ghost($default_color, $hover_color, $active_color, $disabled_color);
    }

    &_link {
      @include btn-link(
          map-get($project-colors, "text-icons", "dark", "secondary"),
          map-get($project-colors, "text-icons", "dark", "tertiary"),
          map-get($project-colors, "text-icons", "dark", "primary"),
          map-get($project-colors, "text-icons", "dark", "quaternary"),
      );
    }

    // sizes

    &_big {
      @include btn-big;
    }

    &_normal {
      @include btn-normal;
    }

    &_small {
      @include btn-small;
    }

    &_link {
      height: auto;
      text-align: left;
      word-break: break-word;
      justify-content: left;
    }
  }


  // customs

  &[custom="white"] {
    @include btn-custom-white;
  }

  &[custom="green"] {
    @include btn-custom-green;
  }

  &[custom="red"] {
    @include btn-custom-red;
  }

  &[custom="black"] {
    @include btn-custom-black;
  }

  &_rounded {
    border-radius: 50%;
  }

}

