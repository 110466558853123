@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/widjets/icon_tools";

span.c,
.text.c,
.c {
  &-white {
    color: #fff;
  }

  &-black {
    color: #000;
  }

  &-gradation-800 {
    color: map-get($project-colors, "gradation", 800);
  }

  &-gradation-700 {
    color: map-get($project-colors, "gradation", 700);
  }

  &-gradation-600 {
    color: map-get($project-colors, "gradation", 600);
  }

  &-gradation-500 {
    color: map-get($project-colors, "gradation", 500);
  }

  &-gradation-400 {
    color: map-get($project-colors, "gradation", 400);
  }

  &-gradation-300 {
    color: map-get($project-colors, "gradation", 300);
  }

  &-gradation-200 {
    color: map-get($project-colors, "gradation", 200);
  }

  &-gradation-100 {
    color: map-get($project-colors, "gradation", 100);
  }

  &-black-400 {
    color: map-get($project-colors, "black", 400);
  }

  &-black-300 {
    color: map-get($project-colors, "black", 300);
  }

  &-black-200 {
    color: map-get($project-colors, "black", 200);
  }

  &-black-100 {
    color: map-get($project-colors, "black", 100);
  }

  &-grey-400 {
    color: map-get($project-colors, "gray", 400);
  }

  &-grey-300 {
    color: map-get($project-colors, "gray", 300);
  }

  &-grey-200 {
    color: map-get($project-colors, "gray", 200);
  }

  &-grey-100 {
    color: map-get($project-colors, "gray", 100);
  }

  &-green-600 {
    color: map-get($project-colors, "green", 600);
  }

  &-green-500 {
    color: map-get($project-colors, "green", 500);
  }

  &-green-400 {
    color: map-get($project-colors, "green", 400);
  }

  &-green-300 {
    color: map-get($project-colors, "green", 300);
  }

  &-green-200 {
    color: map-get($project-colors, "green", 200);
  }

  &-green-100 {
    color: map-get($project-colors, "green", 100);
  }

  &-main-400 {
    color: map-get($project-colors, "main", 400);
  }

  &-main-300 {
    color: map-get($project-colors, "main", 300);
  }

  &-main-200 {
    color: map-get($project-colors, "main", 200);
  }

  &-main-100 {
    color: map-get($project-colors, "main", 100);
  }

  &-red-400 {
    color: map-get($project-colors, "red", 400);
  }

  &-red-300 {
    color: map-get($project-colors, "red", 300);
  }

  &-red-200 {
    color: map-get($project-colors, "red", 200);
  }

  &-red-100 {
    color: map-get($project-colors, "red", 100);
  }

  &-yellow-400 {
    color: map-get($project-colors, "yellow", 400);
  }

  &-yellow-300 {
    color: map-get($project-colors, "yellow", 300);
  }

  &-yellow-200 {
    color: map-get($project-colors, "yellow", 200);
  }

  &-yellow-100 {
    color: map-get($project-colors, "yellow", 100);
  }

  &-blue-400 {
    color: map-get($project-colors, "blue", 400);
  }

  &-blue-300 {
    color: map-get($project-colors, "blue", 300);
  }

  &-blue-200 {
    color: map-get($project-colors, "blue", 200);
  }

  &-blue-100 {
    color: map-get($project-colors, "blue", 100);
  }

  &-ti-light-quaternary {
    color: map-get($project-colors, "text-icons", "light", "quaternary");
  }

  &-ti-light-tertiary {
    color: map-get($project-colors, "text-icons", "light", "tertiary");
  }

  &-ti-light-secondary {
    color: map-get($project-colors, "text-icons", "light", "secondary");
  }

  &-ti-light-primary {
    color: map-get($project-colors, "text-icons", "light", "primary");
  }

  &-ti-dark-quaternary {
    color: map-get($project-colors, "text-icons", "dark", "quaternary");
  }

  &-ti-dark-tertiary {
    color: map-get($project-colors, "text-icons", "dark", "tertiary");
  }

  &-ti-dark-secondary {
    color: map-get($project-colors, "text-icons", "dark", "secondary");
  }

  &-ti-dark-primary {
    color: map-get($project-colors, "text-icons", "dark", "primary");
  }
}

.stroke {
  &-main {
    &-main-400 {
      @include icon-color(map-get($project-colors, "main", 400));
    }

    &-main-300 {
      @include icon-color(map-get($project-colors, "main", 300));
    }

    &-main-200 {
      @include icon-color(map-get($project-colors, "main", 200));
    }

    &-main-100 {
      @include icon-color(map-get($project-colors, "main", 100));
    }
  }
}
