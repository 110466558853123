@font-face {
  font-family: 'suisseIntl';
  src: url('../../fonts/suisseIntl-regular.woff2') format('woff2'), url('../../fonts/suisseIntl-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'suisseIntl';
  src: url('../../fonts/suisseIntl-medium.woff2') format('woff2'), url('../../fonts/suisseIntl-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'namu';
  src: url('../../fonts/namu_pro.woff2') format('woff2'), url('../../fonts/namu_pro.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
