$main_font: "suisseIntl";
$second_font: "namu";

$project-colors: (
  "gradation": (
    800: #ffffff,
    700: #f3f4f6,
    600: #e3e5e8,
    500: #94979e,
    400: #6f6f6f,
    300: #313336,
    200: #131720,
    100: #060b13,
  ),
  "black": (
    400: #060b1380,
    300: #060b13bf,
    200: #060b13d9,
    100: #060b13,
  ),
  "gray": (
    400: #0000000d,
    300: #0000004d,
    200: #00000073,
    100: #00000099,
  ),
  "green": (
    600: #e9fbf2,
    500: #d4f7e4,
    400: #4db27c,
    300: #29a362,
    200: #178249,
    100: #0a5c30,
  ),
  "main": (
    600: #E9FBF2,
    500: #D4F7E4,
    400: #4DB37C,
    300: #29A362,
    200: #178249,
    100: #0A5C30,
    "gradient": linear-gradient(45deg, #1FAD61 0%, #5EAC39 100%)
  ),
  "red": (
    400: #faeaea,
    300: #f5d6d6,
    200: #c33022,
    100: #822017,
  ),
  "yellow": (
    400: #faf5eb,
    300: #f5ebd6,
    200: #da950b,
    100: #916308,
  ),
  "blue": (
    400: #e5eeff,
    300: #ccddff,
    200: #2258c3,
    100: #173b82,
  ),
  "tint": (
    800: rgba(9, 13, 17, 0.75),
    800/45: rgba(9, 13, 17, 0.45),
  ),
  "text-icons": (
    "light": (
      "quaternary": rgba(255, 255, 255, 0.5),
      "tertiary": rgba(255, 255, 255, 0.75),
      "secondary": rgba(255, 255, 255, 0.9),
      "primary": #fff
    ),
    "dark": (
      "quaternary": rgba(#060B13, 0.4),
      "tertiary": rgba(6, 11, 19, 0.8),
      "secondary": rgba(6, 11, 19, 0.9),
      "primary": #060B13
    )
  ),
  "shadow": rgba(0, 0, 0, 0.08),
  "dark-overlay": (
    80: rgba(#000, 0.8),
    60: rgba(#000, 0.6),
    30: rgba(#000, 0.3),
    5: rgba(#000, 0.05),
  ),
  overlay: (
    primary: #0D0A07CC,
    secondary: #0D0A0799,
    tertiary: #0D0A0766,
    quaternary: #0D0A0733,
  ),
);

