@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/vars.md";

@mixin title-styles {
  font-family: $second_font;
  font-weight: 900;
  margin: 0;
  transition: $trs;
  color: map-get($project-colors, "text-icons", "dark", "primary");
  user-select: text;
}

@mixin h1-desktop {
  font-size: 48px;
  line-height: 64px;
}

@mixin h2-desktop {
  font-size: 32px;
  line-height: 40px;
}

@mixin h3-desktop {
  font-size: 24px;
  line-height: 36px;
}

@mixin h4-desktop {
  font-size: 20px;
  line-height: 28px;
}

@mixin h5-desktop {
  font-size: 18px;
  line-height: 24px;
}

@mixin h1-mobile {
  font-size: 32px;
  line-height: 44px;
}

@mixin h2-mobile {
  font-size: 24px;
  line-height: 32px;
}

@mixin h3-mobile {
  font-size: 20px;
  line-height: 28px;
}

@mixin h4-mobile {
  font-size: 18px;
  line-height: 24px;
}

@mixin h5-mobile {
  font-size: 16px;
  line-height: 24px;
}

@mixin weight-regular {
  font-weight: 400;
}
@mixin weight-medium {
  font-weight: 500;
}
@mixin weight-black {
  font-weight: 900;
}

@mixin text {
  transition: color $trs;
  font-family: $main_font;
  font-weight: 400;
  color: map-get($project-colors, "text-icons", "dark", "primary");
  user-select: text;
  white-space: normal;
}
@mixin text-big {
  font-size: 17px;
  line-height: 24px;
}
@mixin text-normal {
  font-size: 15px;
  line-height: 20px;
}
@mixin text-small {
  font-size: 13px;
  line-height: 18px;
}
@mixin text-tiny {
  font-size: 11px;
  line-height: 16px;
}

@mixin span-text {
  font-family: $second_font;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.015em;
  @include span-text-default;
}
@mixin span-text-default {
  font-size: 15px;
  line-height: 16px;
}
@mixin span-text-big {
  font-size: 17px;
  line-height: 16px;
}
@mixin span-text-small {
  font-size: 13px;
  line-height: 16px;
}

//@media (max-width: 1440px) {
//  font-size: calc(32px + (72 - 32) * ((100vw - 360px) / (1440 - 360)));
//  line-height: calc(40px + (80 - 40) * ((100vw - 360px) / (1440 - 360)));
//}
@mixin text-overflow($line_count) {
  display: block;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line_count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;  // добавлено
  word-break: break-all;  // добавлено

  &.expanded {
    -webkit-line-clamp: initial;
    overflow: auto;
  }
}
