@import "src/shared/styles/vars.colors";
@import "src/shared/styles/layout/typography";

$text: "&, & > span";


@mixin badge-size($radius, $padding-horizontal, $height) {
  padding: 0 $padding-horizontal;
  height: $height;
  &.badge {
    &_equal-spacing {
      min-width: $height;
      padding: $padding-horizontal;
    }
  }
  border-radius: $radius;
}

@mixin badge-styles($color, $background, $border: transparent) {
  border: 1px solid $border;

  #{$text} {
    @content;
    color: $color
  }
  background-color: $background
}

@mixin badge {
  #{$text} {
    @include text;
    @include text-small;
    @include weight-regular;
  }
  & {
    transition: color $trs, background-color $trs, border-color $trs;
  }

}
