@import "src/shared/styles/widjets/icon_tools";
@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/layout/typography";


@mixin tab-style($default_color, $hover_color, $active_color, $border_color) {
  border-bottom: 1px solid $border_color;

  &,
  span {
    color: $default_color;
  }

  &:hover {
    &,
    span {
      color: $hover_color;
    }
  }

  &_active {
    &,
    span {
      color: $active_color;
    }
    &:before {
      opacity: 1;
    }
  }
}

@mixin tab-big {
  height: 48px;
  padding: 0 12px;
  &,
  span {
    @include text-big();
  }
}

@mixin tab-normal {
  height: 40px;
  padding: 0 12px;
  gap: 6px;
  &,
  span {
    @include text-normal();
  }
}

@mixin tab-small {
  height: 32px;
  padding: 0 12px;
  gap: 6px;
  &,
  span {
    @include text-normal();
  }
}

@mixin tab {

  position: relative;
  background-color: unset;
  line-height: 100%;
  width: 100%;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    transition: $trs;
    background-color: map-get($project-colors, "main", 300);
    opacity: 0;
  }

  &,
  span {
    @include text();
    user-select: none;
  }
  & {
    transition: color $trs, border-color $trs, background-color $trs;
  }

  .mat-icon {
    @include icon-size(20px)
  }

  &_left-iconly .tab_left-wrapper {
    display: block;
  }

  &_right-iconly .tab_right-wrapper {
    display: block;
  }

  &_icon-wrapper {
    display: none;
  }

  @include tab-style(
      map-get($project-colors, "text-icons", "dark", "quaternary"),
      map-get($project-colors, "text-icons", "dark", "tertiary"),
      map-get($project-colors, "text-icons", "dark", "secondary"),
      map-get($project-colors, "gradation", 600)
  )
}

@mixin tab-iconly($default_color) {
  .mat-icon {
    @include icon-color($default_color);
  }
}

.tab {
  @include tab;

  &_active {
    &,
    span {
      @include weight-medium
    }
    &:before {
      opacity: 1;
    }
  }

  &.tab {
    &_big {
      @include tab-big;
    }

    &_normal {
      @include tab-normal;
    }

    &_small {
      @include tab-small;
    }
  }

  &[custom="white"] {
    &.tab {
      @include tab-style(
          map-get($project-colors, "text-icons", "light", "quaternary"),
          map-get($project-colors, "text-icons", "light", "tertiary"),
          map-get($project-colors, "text-icons", "light", "secondary"),
          map-get($project-colors, "gradation", 300)
      );
    }
  }
}
