@import "src/shared/styles/vars.colors";
@import "src/shared/styles/layout/typography";
@import "src/shared/styles/components/badge/badge.vars";

.sidebar-user-panel {
  .badge {
    pointer-events: none;
    @include badge-styles(map-get($project-colors, "text-icons", "light", "primary"),map-get($project-colors, "main", 200)) {@include text-tiny};
    @include badge-size(10px, 5px, 19px);
    border: 2px solid map-get($project-colors, "gradation", 200);
  }
}
