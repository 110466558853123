@import "src/shared/styles/vars";

@mixin menu {
  position: relative;
  max-height: 400px;
  pointer-events: visible;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 8px 16px map-get($project-colors, "shadow");
  background-color: map-get($project-colors, "gradation", 800);
  border: 1px solid map-get($project-colors, "gradation", 600);
  z-index: 1000;
  overflow: hidden;
  .mat-mdc-menu-content {
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      background-color: map-get($project-colors, "gradation", 750);
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid map-get($project-colors, "gradation", 500);
      border-radius: 4px;
    }
  }
}
