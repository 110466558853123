@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/widjets/icon_tools";

.sidebar {
  div {
    &.accordion-item {
      background-color: unset;
      border-bottom: unset;
      &:hover {
        background-color: unset;
      }
      &_open {
        .mat-icon[data-mat-icon-name="x"] {
          @include icon-color(map-get($project-colors, "main", 400))
        }
      }
    }
  }
}

.request-client {
  div {
    &.accordion-item {
      padding: 12px;
      border-radius: 8px;
      border: 1px solid map-get($project-colors, "gradation", 600);
    }
  }
}

.accordion-item {
  transition: $trs;

  cursor: pointer;

  width: 100%;
  background-color: map-get($project-colors, "gradation", 800);
  border-bottom: 1px solid map-get($project-colors, "gradation", 600);

  &:hover {
    background-color: map-get($project-colors, "gradation", 700);
  }

  &_open {

  }

  &-content {
    overflow: hidden;
  }
}


div.accordion-item {
  &, span, p, * {
    user-select: none;
  }
  &:hover {
    background-color: transparent;
    border-color: map-get($project-colors, "gradation", 500);
  }
}
