@import "src/shared/styles/components/tag.vars";
@import "src/shared/styles/vars.colors";

.picture {
  gl-tag .tag {
    $default_color: (
      color: map-get($project-colors, "text-icons", "light", "primary"),
      background: map-get($project-colors, "dark-overlay", 60)
    );
    $hover_color: map-get($project-colors, "dark-overlay", 80);
    $active_color: map-get($project-colors, "dark-overlay", 60);

    @include tag-size(20px, 2px, 0px, 4px);
    @include tag-icon-size(16px);
    @include tag-primary($default_color, $hover_color, $active_color);
  }
}
