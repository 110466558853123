@import "src/shared/styles/components/menu.vars";

.mat-mdc-menu-panel {
  max-width: 100% !important;
}
div.mat-mdc-menu-panel.menu {
  @include menu;
  margin-top: 4px;
  .mat-mdc-menu-content {
    padding: 0;
    overflow: auto;
  }
  .mdc-list-item__primary-text {
    width: 100%;
  }
}
div.mat-mdc-menu-item {
  .mat-icon {
    margin: 0;
  }
  padding: 0;
  min-height: auto;
  &:hover:not([disabled]), &.cdk-program-focused:not([disabled]), &.cdk-keyboard-focused:not([disabled]), &-highlighted:not([disabled]) {
    background-color: transparent;
  }
}
.mat-mdc-menu-item-text {
  width: 100%;
}
