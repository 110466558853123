@import "src/shared/styles/vars.colors";
@import "src/shared/styles/components/tag.vars";

.tag {
  @include tag;

  &.tag {
    // types

    &_primary {
      @include tag-primary(
        $default_color: (
          color: map-get($project-colors, "text-icons", "light", "primary"),
          background: map-get($project-colors, "main", 300)
        ),
        $hover_color: map-get($project-colors, "main", 400),
        $active_color: map-get($project-colors, "main", 200)
      );
    }

    &_floating {
      @include tag-floating;
    }

    &_secondary {
      @include tag-secondary(
          (
            "color": map-get($project-colors, "main", 200),
            "background": map-get($project-colors, "main", 600)
          ),
          map-get($project-colors, "main", 500),
          (
            "background": map-get($project-colors, "main", 600),
            "border": map-get($project-colors, "main", 400)
          )
      );
    }

    &_outline {
      @include tag-outline;
    }

    // sizes

    &_big {
      @include tag-size(40px, 8px, 4px) {
        @include text-big();
      }
    }

    &_normal {
      @include tag-size(32px, 8px, 4px) {
        @include text-normal;
      }
    }

    &_small {
      @include tag-size(24px, 6px, 4px, 8px, 18px) {
        @include text-small();
      }
    }
  }

  // customs

  &[custom="blue"] {
    @include tag-custom-blue();
  }

  &[custom="red"] {
    @include tag-custom-red();
  }

  &[custom="yellow"] {
    @include tag-custom-yellow();
  }
}
