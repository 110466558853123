@import "src/shared/styles/widjets/icon_tools";
@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/layout/typography";

@mixin tag-size($height, $padding-horizontal, $gap, $radius: 8px, $iconSize: 20px) {
  border-radius: $radius;
  height: $height;
  padding: 0 $padding-horizontal;
  gap: $gap;
  &, & > span {
    @content
  }
  @include tag-icon-size($iconSize);
}

@mixin tag-primary($default_color, $hover_color, $active_color) {
  background-color: map-get($default_color, background);

  &, & > span {
    @include weight-medium();
    color: map-get($default_color, color);
  }

  @include tag-iconly(map-get($default_color, color));

  &:not(:disabled) {
    &:hover, &[status="hover"] {
      background-color: $hover_color;
    }

    &:active, &[status="active"] {
      background-color: $active_color;
    }
  }

}

@mixin tag-floating() {
  $default_color: map-get($project-colors, "text-icons", "dark", "secondary");
  $active_color: map-get($project-colors, "main", 200);
  background-color: map-get($project-colors, "gradation", 800);

  &,
  & > span {
    @include weight-regular();
    color: $default_color;
  }

  @include tag-iconly($default_color);

  &:not(:disabled) {

    &:hover, &[status="hover"] {
      background-color: map-get($project-colors, "gradation", 700);
      border-color: map-get($project-colors, "gradation", 600);
    }

    &:active, &[status="active"] {

      &,
      & > span {
        @include weight-regular();
        color: $active_color;
      }

      @include tag-iconly($active_color);

      box-shadow: 0 8px 16px map-get($project-colors, "shadow");
      background-color: map-get($project-colors, "gradation", 800);
    }
  }
}

@mixin tag-secondary($default_color, $hover_color, $active_color) {
  background-color: map-get($default_color, "background");

  &,
  & > span {
    @include weight-regular();
    color: map-get($default_color, "color");
  }
  @include tag-iconly(map-get($default_color, "color"));

  &:not(:disabled) {
    &:hover, &[status="hover"] {
      background-color: $hover_color;
    }

    &:active, &[status="active"] {
      background-color: map-get($active_color, "background");
      border-color: map-get($active_color, "border");
    }
  }
}

@mixin tag-outline() {
  $default_color: map-get($project-colors, "text-icons", "dark", "tertiary");
  $active_color: map-get($project-colors, "text-icons", "dark", "secondary");
  background-color: transparent;
  border-color: map-get($project-colors, "gradation", 600);

  &,
  & > span {
    @include weight-regular();
    color: $default_color;
  }
  @include tag-iconly($default_color);

  &:not(:disabled) {
    &:hover, &[status="hover"] {
      background-color: map-get($project-colors, "gradation", 700);
      border-color: map-get($project-colors, "gradation", 500);
    }

    &:active, &[status="active"] {
      &,
      & > span {
        color: $active_color;
      }

      @include tag-iconly($active_color);

      background-color: map-get($project-colors, "gradation", 700);
      border-color: map-get($project-colors, "gradation", 500);
    }
  }
}


// other

@mixin tag-disabled {
  $default_color: map-get($project-colors, "text-icons", "dark", "quaternary");
  cursor: not-allowed;
  background-color: map-get($project-colors, "gradation", 500);

  &,
  & > span {
    @include weight-regular();
    color: $default_color;
  }

  &.tag {
    &_primary {
      &, & > span {
        @include weight-medium();
      }
    }
  }

  @include tag-iconly($default_color);
}

@mixin tag {
  border: 1px solid transparent;
  cursor: pointer;
  line-height: 100%;
  width: 100%;

  &_left-iconly .tag_left-wrapper {
    display: flex;
  }

  &_right-iconly .tag_right-wrapper {
    display: flex;
  }

  &_icon-wrapper {
    display: none;
  }

  &,
  & > span {
    @include text;
    @include text-overflow(1);
    user-select: none;
    white-space: nowrap
  }
  & {
    transition: color $trs, border-color $trs, background-color $trs;
  }

  overflow: hidden;

  &.tag {
    &:disabled, &[status="disabled"] {
      @include tag-disabled;
    }
  }

  @include tag-icon-size(20px);
}

@mixin tag-icon-size($size) {
  .mat-icon {
    @include icon-size($size);
  }
  &_icon-wrapper {
    @include icon-height($size);
  }
}

@mixin tag-iconly($default_color) {
  .mat-icon {
    @include icon-color($default_color);
  }
}

@mixin tag-custom-blue {
  &.tag {
    &_primary {
    }

    &_floating {
    }

    &_secondary {
      @include tag-secondary(
          (
            "color": map-get($project-colors, "blue", 200),
            "background": map-get($project-colors, "blue", 400)
          ),
          map-get($project-colors, "blue", 300),
          (
            "background": map-get($project-colors, "blue", 400),
            "border": map-get($project-colors, "blue", 300)
          )
      );
    }

    &_outline {
    }
  }
}

@mixin tag-custom-red {
  &.tag {
    &_primary {
      @include tag-primary(
          (
            "color": map-get($project-colors, "text-icons", "light", "primary"),
            "background": map-get($project-colors, "red", 200)
          ),
          map-get($project-colors, "red", 200),
          map-get($project-colors, "red", 200)
      );
    }

    &_floating {
    }

    &_secondary {
      @include tag-secondary(
          (
            "color": map-get($project-colors, "red", 200),
            "background": map-get($project-colors, "red", 300)
          ),
          map-get($project-colors, "red", 300),
          (
            "background": map-get($project-colors, "red", 300),
            "border": map-get($project-colors, "red", 200)
          )
      );
    }

    &_outline {
    }
  }
}

@mixin tag-custom-yellow {
  &.tag {
    &_primary {
      @include tag-primary(
          (
            "color": map-get($project-colors, "text-icons", "dark", "primary"),
            "background": map-get($project-colors, "yellow", 200)
          ),
          map-get($project-colors, "yellow", 200),
          map-get($project-colors, "yellow", 200)
      );
    }

    &_floating {
    }

    &_secondary {
      @include tag-secondary(
          (
            "color": map-get($project-colors, "yellow", 200),
            "background": map-get($project-colors, "yellow", 300)
          ),
          map-get($project-colors, "yellow", 300),
          (
            "background": map-get($project-colors, "yellow", 300),
            "border": map-get($project-colors, "yellow", 200)
          )
      );
    }

    &_outline {
    }
  }
}
