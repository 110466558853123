@import "./normolize";

@import "./components/button";
@import "./components/input";
@import "./components/select";
@import "./components/selector-point";
@import "./components/accordion";
@import "./components/tag";
@import "./components/table";
@import "./components/checkbox";
@import "./components/drawer";
@import "./components/dropout-point";
@import "./components/menu";
@import "./components/document-panel";
@import "./components/tab";
@import "./components/request-service";
@import "./components/request-card";
@import "./components/operation";
@import "./components/badge/badge";
@import "./components/picture";


html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  padding: 0;
  margin: 0;
  list-style: none;
}

picture {
  height: auto;
}

ul[class] {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}
