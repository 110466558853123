html, body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
}

@import "src/shared/styles/reset";
@import "src/shared/styles/layout/main";
@import "src/shared/styles/widjets/main";
@import "src/shared/styles/modals/main";
@import "src/shared/styles/vars.colors";

img, svg {
  user-select: none;
  -webkit-user-drag: none;
  display: block;
}

.user-select {
  &-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.w100 {
  width: 100%;
}

.full-height {
  height: 100%;
}

// TODO: ПОМЕНЯТЬ ЦВЕТ
::-webkit-scrollbar {
  background-color: map-get($project-colors, "gradation", 800);
  width: 12px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid map-get($project-colors, "gradation", 800);
  background-color: map-get($project-colors, "gradation", 500);
  border-radius: 4px;
}

.cdk-overlay-transparent-backdrop {
  transition: unset !important;
}

.display-block {
  display: block;
}

.hidden {
  display: none;
}

// Стилизация для WebKit браузеров (Chrome, Safari)
* {
  &::-webkit-scrollbar {
    width: 8px; // Ширина вертикального скроллбара
    height: 8px; // Высота горизонтального скроллбара
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc; // Цвет полосы прокрутки
    border-radius: 10px; // Скругление углов полосы прокрутки
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; // Фон трека скроллбара
  }
  scrollbar-width: thin; // Устанавливаем тонкий скроллбар
  scrollbar-color: #ccc transparent; // Цвет полосы прокрутки и трека
}
