@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";

@mixin drawer-box {
  border-radius: 16px;
  background-color: map-get($project-colors, "gradation", 800);
  padding: 16px;
  &_dark {
    background-color: map-get($project-colors, "gradation", 700);
  }
}

@mixin border-box {
  border-radius: 8px;
  border: 1px solid map-get($project-colors, "gradation", 600);
  padding: 14px 12px;
}

.border-box {
  @include border-box;
}

.drawer-box {
  @include drawer-box;
}

.drawer-header {
  .close-btn > .btn {
    &:not(:hover) {
      box-shadow: unset;
    }
  }
}

@mixin drawer-cdk {
  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  right: 0;
}

@mixin mat-modal-reset {
  .mat-mdc-dialog-container {
    --mat-dialog-transition-duration: 0 !important;

    .mdc-dialog__surface {
      border-radius: unset;
      background-color: unset;
      box-shadow: unset;
      overflow: hidden;
      &:before {
        content: unset;
      }
    }
  }
  .mdc-dialog, .mdc-dialog__scrim {
    left: unset;
  }
}

.drawer-cdk {
  @include drawer-cdk;
  @include mat-modal-reset;
}
