@import "src/shared/styles/layout/typography";
@import "src/shared/styles/vars.colors";
@import "src/shared/styles/vars.animations";
@import "src/shared/styles/components/menu.vars";

.select {
  &.autocomplete {
    .input {
      cursor: text;
    }
  }

  .mat-icon[data-mat-icon-name="chevron-down"] {
    transition: transform $trs;
  }

  &_expanded {
    .input {
      border-radius: 8px 8px 0 0;
    }
    .mat-icon[data-mat-icon-name="chevron-down"] {
      transform: rotateX(180deg);
    }
  }
  &-panel-above {
    border-color: map-get($project-colors, "gradation", 600);
    .input {
      cursor: text;
      border-radius: 0 0 12px 12px;
    }
  }
}

.cdk-overlay-pane {
  pointer-events: none;
}

div.mat-mdc-menu-panel.select-panel {
  @include menu;
  border-radius: 0 0 12px 12px;
  &.mat-menu-above {
    border-bottom: none;
    top: -40px !important;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -8px 16px map-get($project-colors, "shadow");
  }
  &:not(.mat-menu-above) {
    border-top: none;
  }
}

//.cdk-overlay-backdrop.selected-pane-overlay {
//  background: map-get($project-colors, "dark-overlay", 30);
//  backdrop-filter: unset;
//  z-index: 999;
//  -webkit-tap-highlight-color: unset;
//}
