@import "src/shared/styles/vars.colors";
@import "src/shared/styles/widjets/icon_tools";

.document-panel {
  mat-icon {
    @include icon-color(map-get($project-colors, "main", 200));
    @include icon-size(20px);
  }
  &-title {
    word-break: break-word;
  }
}
