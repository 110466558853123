@import "src/shared/styles/vars.colors";

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.container {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;

  &_large {
    max-width: 1296px;
  }
}

.vertical-separator {
  border-right: 1px solid map-get($project-colors, "gradation", 600);
}

.list {
  overflow-y: scroll;
}

@mixin item-border-bottom {
  display: block;
  &:not(:last-child) {
    border-bottom: 1px solid map-get($project-colors, "gradation", 600);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.separator {
  background-color: map-get($project-colors, "gradation", 600);

  &:not(.vertical) {
    height: 1px;
  }

  &.vertical {
    width: 1px;
  }
}

.mdc-button__label {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.scroll-x {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.grid {
  display: grid;
}

.without-events {
  pointer-events: none !important;
  * {
    pointer-events: visible;
  }
}

.images-viewer {
  mat-icon {
    &[svgicon="just-check"] {
      path {
        stroke-width: 1px;
      }
    }

    &[svgicon="check-check"] {
      path {
        stroke-width: 1px;
      }
    }
  }
}

.text-right {
  text-align: right;
}
.cdk-virtual-scroll-content-wrapper {
  width: 100%;
  min-width: auto;
}
