@import "src/shared/styles/vars.colors";
@import "src/shared/styles/modals/images-viewer";

.cdk-overlay-backdrop {
  background: map-get($project-colors, "dark-overlay", 60);
  backdrop-filter: blur(1px);
}

.images-viewer {
  @include images-viewer-modal
}

@mixin mat-modal-reset {
  .mat-mdc-dialog-container {
    --mat-dialog-transition-duration: 0 !important;

    .mdc-dialog__surface {
      border-radius: unset;
      background-color: unset;
      box-shadow: unset;
      overflow: hidden;
      &:before {
        content: unset;
      }
    }
  }
  .mdc-dialog, .mdc-dialog__scrim {
    left: unset;
  }
}

.modal-cdk {
  .mat-mdc-dialog-surface {
    padding: 24px;
    background: #fff;
    border-radius: 16px !important;
  }
}
