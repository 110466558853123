
.inline-flex {
  display: inline-flex;
}
.flex {
  display: flex;

  // JUSTIFY
  &_justify-center {
    justify-content: center;
  }

  &_justify-between {
    justify-content: space-between;
  }

  &_justify-end {
    justify-content: flex-end;
  }

  &_justify-start {
    justify-content: flex-start;
  }

  &_justify-self-start {
    justify-self: flex-start;
  }

  &_justify-self-end {
    justify-self: flex-end;
  }

  &_justify-self-center {
    justify-self: center;
  }

  // ALIGN
  &_align-center {
    align-items: center;
  }

  &_align-start {
    align-items: flex-start;
  }

  &_align-stretch {
    align-items: stretch;
  }

  &_align-end {
    align-items: flex-end;
  }

  &_align-self-start {
    align-self: flex-start;
  }

  &_align-self-end {
    align-self: flex-end;
  }

  &_align-self-center {
    justify-self: center;
  }

  // GENERAL
  &_center {
    justify-content: center;
    align-items: center;
  }

  //  DIRECTION
  &_column {
    flex-direction: column;
  }

  &_row {
    flex-direction: row;
  }

  &_column-reverse {
    flex-direction: column-reverse;
  }

  &_row-reverse {
    flex-direction: row-reverse;
  }

  // GROW
  &_grow {
    flex-grow: 1;
    flex-basis: 0;
  }
  &_shrink-0 {
    flex-shrink: 0;
  }
  &_wrap {
    flex-wrap: wrap;
  }
  &-0 {
    flex: 0;
  }
}
.gap {
  &-2 {
    gap: 2px;
  }
  &-3 {
    gap: 3px;
  }
  &-4 {
    gap: 4px;
  }
  &-6 {
    gap: 6px;
  }
  &-8 {
    gap: 8px;
  }
  &-9 {
    gap: 9px;
  }
  &-10 {
    gap: 10px;
  }
  &-12 {
    gap: 12px;
  }
  &-16 {
    gap: 16px;
  }
  &-18 {
    gap: 18px;
  }
  &-20 {
    gap: 20px;
  }
  &-22 {
    gap: 22px;
  }
  &-24 {
    gap: 24px;
  }
  &-25 {
    gap: 25px;
  }
  &-28 {
    gap: 28px;
  }
  &-32 {
    gap: 32px;
  }
  &-36 {
    gap: 36px;
  }
  &-40 {
    gap: 40px;
  }
  &-48 {
    gap: 48px;
  }
  &-50 {
    gap: 50px;
  }
  &-64 {
    gap: 64px;
  }
}
