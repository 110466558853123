@import "src/shared/styles/layout/typography";

span {
  &.span {
    @include span-text;
    &_big {
      @include span-text-big;
    }
    &_small {
      @include span-text-small;
    }
  }
}


h1,
h2,
h3,
h4,
h5,
h6 {
  @include title-styles;
}

h1 {
  @include h1-desktop;
  @media (max-width: map-get($md, "t-h")) {
    @include h1-mobile;
  }
}
h2 {
  @include h2-desktop;
  @media (max-width: map-get($md, "t-h")) {
    @include h2-mobile;
  }
}
h3 {
  @include h3-desktop;
  @media (max-width: map-get($md, "t-h")) {
    @include h3-mobile;
  }
}
h4 {
  @include h4-desktop;
  @media (max-width: map-get($md, "t-h")) {
    @include h4-mobile;
  }
}
h5 {
  @include h5-desktop;
  @media (max-width: map-get($md, "t-h")) {
    @include h5-mobile;
  }
}

.text {
  @include text;
  &_big {
    @include text-big;
  }
  &_normal {
    @include text-normal;
  }
  &_small {
    @include text-small;
  }
  &_tiny {
    @include text-tiny;
  }
  &_regular {
    @include weight-regular;
  }
  &_medium {
    @include weight-medium;
  }
  &_black {
    @include weight-black;
  }
}
