@import "src/shared/styles/vars.colors";
@import "src/shared/styles/layout/typography";
@import "src/shared/styles/components/badge/badge.vars";

.badge {
  @include badge;

  &_big {
    @include badge-size(16px, 12px, 32px);
  }

  &_normal {
    @include badge-size(12px, 8px, 24px);
  }

  &_small {
    @include badge-size(10px, 6px, 20px);
  }

  &_type {
    &-filled {
      @include badge-styles(map-get($project-colors, "text-icons", "dark", "secondary"), map-get($project-colors, "gradation", 600))
    }

    &-filled-color {
      @include badge-styles(map-get($project-colors, "text-icons", "light", "primary"), map-get($project-colors, "main", 300)) {
        @include weight-medium
      }
    }

    &-filled-black {
      //@include badge-styles($color, $background) {
      //  @include weight-medium
      //}
    }

    &-filled-white {
      @include badge-styles(map-get($project-colors, "main", 300), map-get($project-colors, "gradation", 800)) {
        @include weight-medium
      }
    }

    &-transparent {
      //@include badge-styles($color, $background) {
      //  @include weight-medium
      //}
    }
  }
}
