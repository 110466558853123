@import "src/shared/styles/vars.colors";
@import "src/shared/styles/layout/typography";

.mdc-button__label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &, span {
    @include text-big;
    @include weight-regular;
    color: map-get($project-colors, "gradation", 300);
  }
}

.selector-point {
  $second_color: map-get($project-colors, "gradation", 100);
  $default_color: map-get($project-colors, "gradation", 300);

  &:not(&_active):hover, &_active {
    .mdc-button__label &, span{
      color: $second_color;
    }
  }
  &:not(&_active):hover {
    background-color: map-get($project-colors, "gradation", 600);
  }

}
